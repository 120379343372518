import { CubismFramework, Option } from '@framework/live2dcubismframework';

import * as LAppDefine from './lappdefine';
import { LAppLive2DManager } from './lapplive2dmanager';
import { LAppPal } from './lapppal';
import { LAppTextureManager } from './lapptexturemanager';
import { LAppView } from './lappview';

export let canvas: HTMLCanvasElement = null;

export let s_instance: LAppDelegate = null;

export let gl: WebGLRenderingContext = null;

export let frameBuffer: WebGLFramebuffer = null;

export class LAppDelegate {
  _cubismOption: Option; // Cubism SDK Option
  _view: LAppView;
  _captured: boolean;
  _mouseX: number;
  _mouseY: number;
  _isEnd: boolean;
  _textureManager: LAppTextureManager;

  //构造器
  constructor() {
    this._captured = false;
    this._mouseX = 0.0;
    this._mouseY = 0.0;
    this._isEnd = false;

    this._cubismOption = new Option();
    this._view = new LAppView();
    this._textureManager = new LAppTextureManager();
  }

  //单例模式
  public static getInstance(): LAppDelegate {
    if (s_instance == null) {
      s_instance = new LAppDelegate();
    }
    return s_instance;
  }

  public static releaseInstance(): void {
    if (s_instance != null) {
      s_instance.release();
    }
    s_instance = null;
  }

  //初始化浏览器相关环境
  public initialize(initialCanvas: HTMLCanvasElement): boolean {
    canvas = initialCanvas;
    //初始化Canvas元素
    canvas.width = LAppDefine.CanvasSize.width;
    canvas.height = LAppDefine.CanvasSize.height;

    //WebGL初始化
    // @ts-ignore
    gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (!gl) {
      alert('Cannot initialize WebGL. This browser does not support.');
      gl = null;

      document.body.innerHTML = 'This browser does not support the <code>&lt;canvas&gt;</code> element.';
      return false;
    }
    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    if (!frameBuffer) {
      frameBuffer = gl.getParameter(gl.FRAMEBUFFER_BINDING);
    }

    canvas.addEventListener("mousedown", onClickBegan);
    canvas.addEventListener("mousemove", onMouseMoved);
    canvas.addEventListener("mouseup", onClickEnded);

    this.initializeCubism();

    return true;
  }

  public release(): void {
    this._textureManager.release();
    this._textureManager = null;

    this._view.release();
    this._view = null;

    LAppLive2DManager.releaseInstance();

    CubismFramework.dispose();
  }

  //当页面大小改变时
  public onResize(ratio: number): void {
    canvas.width = LAppDefine.CanvasSize.width * ratio;
    canvas.height = LAppDefine.CanvasSize.height * ratio;
    this._view.initialize();
    // this._view.initializeSprite();
    const viewport: number[] = [0, 0, canvas.width, canvas.height];
    gl.viewport(viewport[0], viewport[1], viewport[2], viewport[3]);
  }

  public run(): void {
    const loop = (): void => {
      if (s_instance == null) {
        return;
      }

      LAppPal.updateTime();

      gl.clearColor(0.0, 0.0, 0.0, 0);
      gl.enable(gl.DEPTH_TEST);
      gl.depthFunc(gl.LEQUAL);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      gl.clearDepth(1.0);
      gl.enable(gl.BLEND);
      gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

      this._view.render();

      requestAnimationFrame(loop);
    };
    loop();
  }

  public createShader(): WebGLProgram {
    // バーテックスシェーダーのコンパイル
    const vertexShaderId = gl.createShader(gl.VERTEX_SHADER);

    if (vertexShaderId == null) {
      LAppPal.printMessage('failed to create vertexShader');
      return null;
    }

    const vertexShader: string =
      'precision mediump float;' +
      'attribute vec3 position;' +
      'attribute vec2 uv;' +
      'varying vec2 vuv;' +
      'void main(void)' +
      '{' +
      '   gl_Position = vec4(position, 1.0);' +
      '   vuv = uv;' +
      '}';

    gl.shaderSource(vertexShaderId, vertexShader);
    gl.compileShader(vertexShaderId);

    // フラグメントシェーダのコンパイル
    const fragmentShaderId = gl.createShader(gl.FRAGMENT_SHADER);

    if (fragmentShaderId == null) {
      LAppPal.printMessage('failed to create fragmentShader');
      return null;
    }

    const fragmentShader: string =
      'precision mediump float;' +
      'varying vec2 vuv;' +
      'uniform sampler2D texture;' +
      'void main(void)' +
      '{' +
      '   gl_FragColor = texture2D(texture, vuv);' +
      '}';

    gl.shaderSource(fragmentShaderId, fragmentShader);
    gl.compileShader(fragmentShaderId);

    // プログラムオブジェクトの作成
    const programId = gl.createProgram();
    gl.attachShader(programId, vertexShaderId);
    gl.attachShader(programId, fragmentShaderId);

    gl.deleteShader(vertexShaderId);
    gl.deleteShader(fragmentShaderId);

    // リンク
    gl.linkProgram(programId);

    gl.useProgram(programId);

    return programId;
  }

  public initializeCubism(): void {
    // setup cubism
    this._cubismOption.logFunction = LAppPal.printMessage;
    this._cubismOption.loggingLevel = LAppDefine.CubismLoggingLevel;
    CubismFramework.startUp(this._cubismOption);

    // initialize cubism
    CubismFramework.initialize();

    // load model
    LAppLive2DManager.getInstance();

    LAppPal.updateTime();
    this._view.initialize();
    // this._view.initializeSprite();
  }

  public getView(): LAppView {
    return this._view;
  }

  public getTextureManager(): LAppTextureManager {
    return this._textureManager;
  }
}

//鼠标点击事件
function onClickBegan(e: MouseEvent): void {
  if (!s_instance._view) {
    LAppPal.printMessage('view notfound');
    return;
  }
  s_instance._captured = true;

  const posX: number = e.pageX;
  const posY: number = e.pageY;

  s_instance._view.onTouchesBegan(posX, posY);
}

function onMouseMoved(e: MouseEvent): void {
  if (!s_instance._captured) {
    return;
  }

  if (!s_instance._view) {
    LAppPal.printMessage('view notfound');
    return;
  }

  const rect = (e.target as Element).getBoundingClientRect();
  const posX: number = e.clientX - rect.left;
  const posY: number = e.clientY - rect.top;

  s_instance._view.onTouchesMoved(posX, posY);
}

function onClickEnded(e: MouseEvent): void {
  s_instance._captured = false;
  if (!s_instance._view) {
    LAppPal.printMessage('view notfound');
    return;
  }

  const rect = (e.target as Element).getBoundingClientRect();
  const posX: number = e.clientX - rect.left;
  const posY: number = e.clientY - rect.top;

  s_instance._view.onTouchesEnded(posX, posY);
}