import { LAppDelegate } from './lappdelegate';
import { LAppLive2DManager } from "./lapplive2dmanager";

// @ts-ignore
// 注入到全局作用域中
window.live2d = {
  run(canvas: HTMLCanvasElement): boolean {
    if (LAppDelegate.getInstance().initialize(canvas) == false) {
      return;
    }
    LAppDelegate.getInstance().run();
  },
  release(): void {
    LAppDelegate.releaseInstance();
  },
  resize(ratio: number) {
    LAppDelegate.getInstance().onResize(ratio);
  },
  nextModel() {
    LAppLive2DManager.getInstance().nextScene();
  }
};